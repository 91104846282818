import type { QueryOptions } from '@apollo/client';
import { getLog } from '@aurora/shared-utils/log';
import { useContext } from 'react';
import type {
  NodeTagPropertiesQuery,
  NodeTagPropertiesQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import type { Optional } from '@aurora/shared-types/community';
import AppContext from '../../context/AppContext/AppContext';
import useQueryWithTracing from '../../useQueryWithTracing';
import type { ExtendedQueryResult } from '../../useQueryWithTracing';
import nodePropertiesQuery from './NodeTagProperties.query.graphql';

const log = getLog(module);

/**
 * Gets node-specific properties for a node.
 *
 * @param module The module
 * @param variables any node property query variables.
 * @param options optional query options
 * @param useCache whether to use the request scoped cached value if one exists.
 *
 * @author Dolan Halbrook
 */
export default function useNodeTagProperties(
  module: NodeModule | string,
  variables?: Optional<NodeTagPropertiesQueryVariables, 'id'>,
  options?: QueryOptions,
  useCache = true
): ExtendedQueryResult<NodeTagPropertiesQuery, NodeTagPropertiesQueryVariables> {
  const { contextNode } = useContext(AppContext);
  const modifiedVariables = { id: contextNode.id, ...variables };
  const result = useQueryWithTracing<NodeTagPropertiesQuery, NodeTagPropertiesQueryVariables>(
    module,
    nodePropertiesQuery,
    {
      ...(!useCache && { fetchPolicy: 'network-only' }),
      ...options,
      variables: modifiedVariables
    }
  );
  if (result?.error) {
    log.error(result.error, 'Error getting node tag properties for %O', modifiedVariables);
  }
  return result;
}
