import React from 'react';
import dynamic from 'next/dynamic';
import { getLog } from '@aurora/shared-utils/log';
import type { UserViewFragment } from '../../../types/graphql-types';
import type { ItemType } from '../../../types/enums';
import { UserViewVariant } from '../../../types/enums';
import mergeVariantProps from './UserViewDefaultProps';
import type { ItemViewFC, ItemViewVariantFC } from '../../entities/types';
import type { UserViewCommonProps } from './types';

const log = getLog(module);

const UserViewInline = dynamic(() => import('./UserViewInline/UserViewInline'));
const UserViewCard = dynamic(() => import('./UserViewCard/UserViewCard'));
const UserViewTableRow = dynamic(() => import('./UserViewTableRow/UserViewTableRow'));

const variantToComponentMap: Record<
  UserViewVariant,
  ItemViewVariantFC<UserViewFragment, ItemType.USER, UserViewVariant>
> = {
  inline: UserViewInline,
  card: UserViewCard,
  tableRow: UserViewTableRow
};

/**
 * The base user view component that renders the user view specified by
 * the <code>variant</code> parameter. This component should be used anywhere a user is rendered.
 *
 * @constructor
 *
 * @author Adam Ayres
 */
const UserView: ItemViewFC<UserViewFragment, ItemType.USER, UserViewVariant> = ({
  entity: user,
  variant = { type: UserViewVariant.INLINE },
  className
}) => {
  const UserViewComponent = variantToComponentMap[variant?.type];

  if (UserViewComponent) {
    const defaultProps = mergeVariantProps<UserViewCommonProps>(variant);

    return (
      <UserViewComponent
        entity={user}
        className={className}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...{ ...defaultProps, ...variant.props }}
      />
    );
  }
  log.warn('No registered user view for variant type [%s]', variant.type);
  return null;
};

export default UserView;
